<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      top="0"
      :class="inputmode == 0 ? 'detail-dialog' : 'detail-dialog catalog-dialog'"
      @close="diaClose"
      v-if="dialogVisible"
      destroy-on-close
    >
      <el-main
        class="dislogContent posiR"
        v-loading="loading"
        style="padding-top: 0; min-height: 100%"
      >
        <div v-if="detailData != ''">
          <b-breadcrumb class="ft11">
            <b-breadcrumb-item>{{ detailData.cat.name }}</b-breadcrumb-item>
            <b-breadcrumb-item>{{ detailData.subCat.name }}</b-breadcrumb-item>
          </b-breadcrumb>
          <template v-if="inputmode == 0">
            <b-row class="pdp_brief">
              <div class="pdp_thumbnail">
                <ul>
                  <li
                    v-for="(item, index) in itemPhotos"
                    :key="index"
                    @click="changeSlide(index)"
                  >
                    <img :src="url + item.path" />
                  </li>
                </ul>
              </div>
              <div class="gallery">
                <swiper class="pdp_zoom" :options="swiperOption" ref="mySwiper">
                  <swiper-slide
                    class="slide"
                    v-for="(item, index) in itemPhotos"
                    :key="index"
                  >
                    <div class="swiper-zoom-container">
                      <img :src="url + item.path" />
                    </div>
                  </swiper-slide>
                </swiper>
                <div class="swiper-pagination-product paginations" slot="pagination"></div>
              </div>

              <div class="pdp_details">
                <detail-info
                  :price-loading="priceLoading"
                  :detail-data="detailData"
                  :inputmode="inputmode"
                  :type="1"
                  :dialog-visible="dialogVisible"
                  :subopt="subopt"
                  @submitData="getsubmitData"
                  @change-color="changecolorEvent"
                  @showsend1Dialog="showsend1Dialog"
                  @upload-dialog="showuploadDialog"
                  @changeMaterial="changeMaterial"
                  @checkBoxEvent="checkBoxEvent"
                ></detail-info>
              </div>
            </b-row>
            <div
              v-if="
                detailData.product.len ||
                detailData.product.wd_dia ||
                detailData.product.hgt ||
                detailData.product[detailData.descName] ||
                detailData.product[detailData.subOptDescName] ||
                detailData.product[detailData.remarkName]
              "
              class="pdp_description mgt20 pdt40"
            >
              <h4
                v-if="
                  detailData.product.len ||
                  detailData.product.wd_dia ||
                  detailData.product.hgt ||
                  detailData.product[detailData.descName] ||
                  detailData.product[detailData.subOptDescName]
                "
              >
                {{ $t("productfilter.description") }}
              </h4>
              <div class="mgt30">
                <!-- {{subopt.selectedSubOptSizeStr}} -->
                <span
                  v-if="
                    detailData.product.len ||
                    detailData.product.wd_dia ||
                    detailData.product.hgt
                  "
                  v-html="subopt.selectedSubOptSizeStr"
                ></span>
                <p
                  v-if="detailData.product[detailData.descName]"
                  v-html="
                    detailData.product[detailData.descName].replace(
                      /\r\n/g,
                      '<br/>'
                    )
                  "
                ></p>
                <p>
                  {{ detailData.product[detailData.subOptDescName] }}
                </p>
                <br />
              </div>
              <template
                class="mgt30"
                v-if="detailData.product[detailData.remarkName]"
              >
                <h4>{{ $t("productfilter.remark") }}</h4>
                <div
                  class="info-container"
                  v-html="
                    detailData.product[detailData.remarkName].replace(
                      /\r\n/g,
                      '<br/>'
                    )
                  "
                ></div>
              </template>
              <div
                class="cert-icons mgt50"
                v-if="detailData.certInfo.length > 0"
              >
                <ul>
                  <li
                    v-for="(item, index) in detailData.certInfo"
                    :key="index"
                    @click="showCertInfo(item)"
                  >
                    <img :src="url + item.path" v-if="item.path" />
                    <em v-else> {{ item.name_tc }}</em>
                  </li>
                </ul>
              </div>
            </div>
          </template>

          <!-- PDF Viewer -->
          <template v-else>
            <b-row class="pdp_brief catalog_view">
              <div class="pdp_thumbnail">
                <ul>
                  <li
                    v-for="(item, index) in itemPhotos"
                    :key="index"
                    @click="changeSlide(index)"
                  >
                    <img v-if="item.path" :src="url + item.path" />
                  </li>
                </ul>
              </div>
              <div class="catalog">
                <div
                  class="catalog_preview"
                  style="flex: auto; position: relative"
                >
                  <div class="iframe-div" v-if="detailData.product.file_catalog">
                    <!--PDFViewer
                      data-root="product-detail"
                      :data-name="detailData.product.name"
                      :data-str="url + detailData.product.file_catalog"
                      :data-id="detailData.product.id"
                    /-->
                    <iframe :src="url + detailData.product.file_catalog" frameborder="0"></iframe>
                  </div>
                </div>
              </div>

              <div class="catalog_details">
                <detail-info
                  :price-loading="priceLoading"
                  :detail-data="detailData"
                  :hl-status="hlStatus"
                  :type="1"
                  :inputmode="inputmode"
                  :subopt="subopt"
                  :dialog-visible="dialogVisible"
                  @submitData="getsubmitData"
                  @change-color="changecolorEvent"
                  @showsend1Dialog="showsend1Dialog"
                  @upload-dialog="showuploadDialog"
                  @changeMaterial="changeMaterial"
                  @checkBoxEvent="checkBoxEvent"
                ></detail-info>
              </div>
            </b-row>
          </template>
        </div>
      </el-main>
    </el-dialog>

    <Upload
      ref="upload"
      :upload-dialog="uploadDialog"
      @uploadChange="closeUploadChange"
    ></Upload>
    <confirm-message
      :detail-data="detailData"
      :is-visble="sendDialog"
      v-if="detailData != ''"
    ></confirm-message>
    <send-message
      :detail-data="detailData"
      :submit-data="submitData"
      :is-visble="send1Dialog"
      v-if="detailData != ''"
      @dialogChange="closedialogChange"
    ></send-message>
  </div>
</template>

<script>
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import productDetailInfo from "@/components/ProductDetailInfo";
import Upload from "@/components/Upload";
import confirmMessage from "@/components/ConfirmMessage";
import sendMessage from "@/components/SendMessage";
import { getProductsubopt } from "@/api/product";
import PDFViewer from '@/components/PDFViewerPdfjs';

export default {
  components: {
    swiper,
    "swiper-slide": swiperSlide,
    Upload,
    "confirm-message": confirmMessage,
    "send-message": sendMessage,
    PDFViewer,
    "detail-info": productDetailInfo
  },
  data() {
    return {
      swiperOption: {
        loop: false,
        autoplay: false,
        pagination: {
          el: ".swiper-pagination-product",
          clickable: true,
        },
        zoom: {
          maxRatio: 3,
        },
      },
      loading: false,
      dialogVisible: false,
      detailData: "",
      url: process.env.VUE_APP_IMG_URL,
      value: "",
      programText: true,
      uploadDialog: false,
      sendDialog: true,
      send1Dialog: false,

      itemPhotos: [],
      hlStatus: "",
      submitData: [],
      subopt: {},
      priceLoading: false,
      forRegion: window.localStorage.getItem("forRegion")
    };
  },

  props: {
    isVisible: {
      type: Boolean,
      default: false
    },
    pictLoading: {
      type: Boolean,
      default: false
    },
    inputmode: {
      type: Number,
      default: 0
    },
    // subopt: {
    //   type: Object,
    // },
    hl: {
      type: [String,Number]
    }
  },
  created() {},
  watch: {
    // 监听 addOrUpdateVisible 改变
    isVisible() {
      this.dialogVisible = this.isVisible;
    },
    pictLoading() {
      this.loading = this.pictLoading;
    },
    hl() {
      this.hlStatus = this.hl;
    }
  },

  methods: {
    getDetail(datas) {
      this.detailData = datas;
      this.itemPhotos = datas.itemPhotos;
      this.getsubopt(this.detailData.selectedOpt);
    },
    getsubopt(id, color_code) {
      this.priceLoading = true;
      const data = {
        forRegion: this.forRegion
      };
      getProductsubopt(id, data).then((res) => {
        this.subopt = res.data;
        if (this.subopt.subOpts[0].priceInq == 1) {
          this.subopt.price = this.$t("productfilter.product_tbc");
        }
        this.priceLoading = false;
        if (color_code) {
          let imgurl = [];
          let obj = {};
          obj.path = this.subopt.selectedSubOptImg;
          imgurl.push(obj);
          this.itemPhotos = imgurl;
        }
      });
    },
    diaClose() {
      this.$emit("reportChange", false);
      this.detailData = "";
    },

    changecolorEvent(data, item) {
      if (item.pattern_img) {
        this.itemPhotos = data;
      }
      console.log(data);

      this.getsubopt(item.id, item.color_code);
    },
    changeMaterial(item) {
      this.getsubopt(item.id);
    },
    checkBoxEvent(val) {
      this.subopt.selectedSubOptSizeStr = val.subOptSizeStr;
    },
    showsend1Dialog(data) {
      this.send1Dialog = data;
    },
    showuploadDialog(boolen) {
      this.uploadDialog = boolen;
    },
    // sendFrom() {
    //     let alertmsg=this.$t('productfilter.contact_alert_select_project');
    //     if (this.submitData.project.id == "") {
    //         this.$message.error(alertmsg);
    //     }else{
    //         informsnotify(this.submitData).then(res => {
    //             if (res.code == 200) {
    //             this.$message.success("发送成功！");
    //             this.send1Dialog = false;
    //             }
    //         });
    //     }

    // },
    getsubmitData(data) {
      this.submitData = data;
    },
    changeSlide(index) {
      this.swiper.slideTo(index);
    },
    closeUploadChange(status) {
      this.uploadDialog = status;
    },
    closedialogChange(status) {
      this.send1Dialog = status;
    }
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    }
  }
};
</script>

<style lang="scss" scoped>
.dislogContent {
  .breadcrumb {
    padding-left: 7.5px;
    padding-right: 7.5px;
    margin-bottom: 15px;
    @media (max-width: 1023px) {
      margin-bottom: 20px;
    }
  }
  .pdp_brief {
    max-width: 1530px;
    padding: 0 7.5px;
    margin: 0 auto;
    .pdp_thumbnail {
      /*width: 10%;
      min-width: 75px;
      min-height: 120px;
      display: block;
      height: 90%;
      overflow-y: auto;
      overflow-x: hidden;
      position: relative;*/
      display: block;
      padding: 0;
      max-width: 100px;
      width: 6%;
      @media (max-width: 1023px) {
        display: none;
      }
      ul{
        li {
          margin-bottom: 0.5em;
          text-align: center;
          img {
            width: 100%;
            vertical-align: middle;
            border: 0;
            object-fit: contain;
            aspect-ratio: 1/1;
          }
        }
      }
    }
    .gallery {
      margin-bottom: 50px !important;
      padding: 0px 5%;
      min-height: 0;
      width: 60%;
      @media (max-width: 1023px) {
        padding: 0;
        width: 100%;
      }
      .pdp_zoom {

        .swiper-zoom-container {
          aspect-ratio: 4/3;
          @media (max-width: 1023px) {
            aspect-ratio: unset;
          }
          img {
            max-width: 100%;
            margin: 0 auto auto;
            width: 100%;
            /*max-height: 70vh;*/
            @media (max-width: 1023px) {
              aspect-ratio: 1/1;
            }
          }
        }
      }
    }
    .pdp_details {
      width: 34%;
      padding: 0px;
      @media (max-width: 1023px) {
        width: 100%;
        padding: 0;
      }
    }

    &.catalog_view {
      max-width: none;
      padding: 0;
      .pdp_thumbnail {
        @media (max-width: 1023px) {
          display: block;
          width: 100%;
          max-width: none;
          margin-bottom: 20px;
          order: 2;
          ul {
            display: flex;
            li {
              max-width: 50px;
            }
          }
        }
      }
      .catalog {
        /*margin-bottom: 50px;*/
        padding: 0px 2%;
        min-height: calc(100vh - 200px);
        width: calc(94% - 400px);
        order: 1;
        @media (max-width: 1023px) {
          margin-top: 0;
          margin-bottom: 0px;
          padding: 0px 7.5px;
          width: 100%;
        }
        .catalog_preview {
          width: auto;
          height: 100%;
          flex: auto;
          position: relative;
          .iframe-div {
            height: 100%;
            div {
              height: 100%;
            }
          }
          iframe {
            width: 100%;
            height: 77.5vh;
            border: 5px solid #e5e5e5;
            @media (max-width: 1023px) {
              height: 65vh;
              min-height: 420px;
              border: 1px solid #e7e7e7;
            }
          }
        }
      }
      .catalog_details {
        float: right;
        width: 400px;
        order: 3;
        @media (max-width: 1023px) {
          width: 100%;
        }
      }
    }
  }

  .pdp_description {
    border-top: #ddd 1px solid;
  }
  .cert-icons {
    li {
      display: inline-block;
      margin: 0 1%;
      img {
        max-height: 50px;
        width: auto;
      }
    }
  }
}
.detail-dialog {
  padding: 40px 0;
  /deep/ .el-dialog__header {
    padding-top: 0;
    .el-dialog__headerbtn {
      right: 10px;
      top: 10px;
    }
  }
  /deep/.el-dialog {
    height: 100%;
    width: calc(100% - 30px);
    max-width: 1400px;
    overflow: hidden;
    margin-bottom: 0;
    // @media (min-width: 768px){
    //   width: 750px;
    // }
    // @media (min-width: 992px){
    //   width: 970px;
    // }
    // @media (min-width: 1200px){
    //   width: 1170px;
    // }
  }
  /deep/ .el-dialog__body {
    max-height: calc(100% - 50px);
    overflow-x: hidden;
    overflow-y: auto;
    margin-top: 20px;
    padding: 0 0 20px;
    height: 100%;
    color: #555;
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 3px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.3);
      -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }
  }

  &.catalog-dialog {
    &/deep/.el-dialog {
      max-width: none;

      .el-dialog__body {
        padding-bottom: 0;
      }
    }
  }
}

/deep/.el-loading-mask {
  position: fixed;
}
.detail-dialog /deep/.el-loading-mask {
  position: absolute;
}
/deep/ .el-collapse-item__content {
  padding-bottom: 0;
}
.upload-dialog {
  .dislog-title {
    font-family: "Montserrat", Arial, Sans-serif;
    font-size: 18px;
    line-height: 1.35;
    text-transform: capitalize;
  }
  .jconfirm-box {
    margin-top: 20px;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    border: 1px dashed rgba(85, 85, 85, 0.5);
    background: #efefef;
    /deep/.el-upload {
      display: block;
    }
    .dz-default {
      padding: 70px 20px;
    }
  }
}

/deep/ .el-dialog__close {
  font-size: 20px;
  font-weight: bold;
}
/deep/ .el-dialog {
  height: 80%;
}


.paginations {
  position: static;
  margin-top: 20px;
  display: none;
  span {
    cursor: pointer;
  }
  @media (max-width: 1023px) {
    /*margin-top: 5px;*/
    display: flex;
    justify-content: center;
  }
}
/deep/ .swiper-pagination-bullet {
  width: 50px;
  border-radius: 0;
  opacity: 1;
  margin: 0 2px;
  padding: 15px 0;
  background: none;
  @media (max-width: 1024px) {
    width: 40px;
  }
  @media (max-width: 767px) {
    max-width: 30px;
  }
  &::before {
    display: block;
    width: 100%;
    height: 2px;
    background: #d6d6d6;
    content: " ";
  }
  &:hover::before {
    background: #869791;
  }
}
/deep/ .swiper-pagination-bullet-active {
  background: none;
  &::before {
    background: #869791;
  }
}

/deep/ .catalog_preview {
  .swiper-pagination-bullet {
    display: none;
  }
}
</style>
