<template>
  <div>
    <div>
      <button @click="onPrevPage()">Previous</button>
      <button @click="onNextPage()">Next</button>
      &nbsp; &nbsp;
      <span>Page: <span id="page_num"></span> / <span id="page_count"></span></span>
    </div>

    <canvas
      id="viewer-dc-view"
      class="customViewer"
    ></canvas>

  </div>
</template>
<script setup>
import { toRef, ref, onBeforeMount, onMounted } from 'vue';

// import useAttachment from '@/views/common/hook/useAttachment';
const props = defineProps({
  dataRoot: {
    type: String,
    default: "root"
  },
  dataStr: {
    type: String,
    default: ""
  },
  dataName: {
    type: String,
    default: "Preview.pdf"
  },
  dataId: {
    type: Number,
    default: 0
  }
})
const inputDataRoot = toRef(props, "dataRoot");
const inputDataStr = toRef(props, "dataStr");
const inputDataName = toRef(props, 'dataName');
const inputDataId = toRef(props, "dataId");
// const pdfjsLib = ref(null);
// var pdfjsLib;
// const viewerDivId = "viewer-dc-view-" + inputDataRoot.value + "-" + inputDataId.value;
const viewerDivId = "viewer-dc-view";

// Loaded via <script> tag, create shortcut to access PDF.js exports.
var { pdfjsLib } = globalThis;

var pdfDoc = null,
    pageNum = 1,
    pageRendering = false,
    pageNumPending = null,
    scale = 0.8;

onMounted(async ()=> {
  // await initPdfjs();
  // await initViewerWithContent();
})

/**
 * Get page info from document, resize canvas accordingly, and render page.
 * @param num Page number.
 *
 * */
const renderPage = (num) => {
  pageRendering = true;
  // Using promise to fetch the page
  pdfDoc.getPage(num).then(function(page) {
    var canvas = document.getElementById(viewerDivId);
    var ctx = canvas.getContext('2d');
    var viewport = page.getViewport({scale: scale});
    canvas.height = viewport.height;
    canvas.width = viewport.width;

    // Render PDF page into canvas context
    var renderContext = {
      canvasContext: ctx,
      viewport: viewport
    };
    var renderTask = page.render(renderContext);

    // Wait for rendering to finish
    renderTask.promise.then(function() {
      pageRendering = false;
      if (pageNumPending !== null) {
        // New page rendering is pending
        renderPage(pageNumPending);
        pageNumPending = null;
      }
    });
  });

  // Update page counters
  document.getElementById('page_num').textContent = num;
}

/**
 * If another page rendering in progress, waits until the rendering is
 * finised. Otherwise, executes rendering immediately.
 */
const queueRenderPage = (num) => {
  if (pageRendering) {
    pageNumPending = num;
  } else {
    renderPage(num);
  }
}

/**
 * Displays previous page.
 */
const onPrevPage = () => {
  if (pageNum <= 1) {
    return;
  }
  pageNum--;
  queueRenderPage(pageNum);
}

/**
 * Displays next page.
 */
const onNextPage = () => {
  if (pageNum >= pdfDoc.numPages) {
    return;
  }
  pageNum++;
  queueRenderPage(pageNum);
}

const loadPdf = () => {
  console.log("loadPdf");
  const url = inputDataStr.value;
  /**
   * Asynchronously downloads PDF.
   */
  pdfjsLib.getDocument(url).promise.then(function(pdfDoc_) {
    pdfDoc = pdfDoc_;
    document.getElementById('page_count').textContent = pdfDoc.numPages;

    // Initial/first page rendering
    renderPage(pageNum);
  });

}

const initViewerWithContent = () => {

  pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.6.82/pdf.worker.min.mjs';

  const url = inputDataStr.value;
  /**
   * Asynchronously downloads PDF.
   */
  pdfjsLib.getDocument(url).promise.then(function(pdfDoc_) {
    pdfDoc = pdfDoc_;
    document.getElementById('page_count').textContent = pdfDoc.numPages;

    // Initial/first page rendering
    renderPage(pageNum);
  });

}

const initPdfjs = () => {
  // if (pdfjsLib == null) {
    // load and init. the adobe pdf viewer
    let script = document.createElement('script')
    script.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.6.82/pdf.min.mjs')
    script.setAttribute('type', 'module')
    document.head.appendChild(script)
    let style = document.createElement('style')
    style.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.6.82/pdf_viewer.min.css')
    document.head.appendChild(style)

    // The workerSrc property shall be specified.
    // pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.6.82/pdf.worker.min.mjs';
  // }

}

</script>

<style scoped>
div.customViewer  {
  height: 100%;
  width: 100%;
}
</style>
